.input-connector .state {
    top: 32px;
    right: 0px;
    bottom: auto;
    left: auto;
}

.input-connector .oscillating circle {
    stroke: red;
}
