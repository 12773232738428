
.relay-box {
    border: solid black 2px;
    box-shadow: 3px 3px 3px #666;
    background-color: white;
    width: 80px;
    height: 70px;
}

.RELAY-ON .input-connector-row,
.RELAY-OFF .input-connector-row {
    justify-content: space-between;
} 
.RELAY-ON .output-connector-row {
    justify-content: right;
} 



/* marching ants animation on wires and coil
*/
@keyframes marching_ants {
    0% {
        stroke-dashoffset: 0;
    }

    100% {
        stroke-dashoffset: 16;
    }
}

.relay-box  .coil {
    stroke: black;
    stroke-width: 2;
    fill: none;
    stroke-width: 1;
}

.relay-box  .coil.on,
.relay-box  .wire.on {
    stroke: black;
    animation: marching_ants 0.34s steps(12) infinite;
    stroke-dasharray: 2;
}

.relay-box  .magnet {
    fill: black;
}


/* wire junction and switch */
.relay-box line.wire,
.relay-box path.wire,
.wire-junction line.wire,
.wire-junction path.wire {
    stroke: black;
    stroke-width: 2;
}

.wire-junction circle.wire,
.wire-junction rect.wire {
    fill: black;
}

.wire-junction line.wire.on,
.wire-junction circle.wire.on,
.wire-junction path.wire.on,
.wire-junction rect.wire.on {
    stroke: blue;
    fill: blue;
}

.wire-junction .oscillating .downleg {
    stroke: red;
}
