.helpbox {
    color: #fff;
    background-color: rgb(0, 129, 194);
    padding: 1em;
    margin-bottom: 1em;
    width: min-content;
    min-width: 100%;
}
.helpbox code {
    color: white;
}
.helpbox-arrow {
    background-color: rgb(0, 129, 194);
    position: relative;
    top: 20px;
    margin-left: 30px;
    transform: translateY(25%) rotate(45deg);
    height: 20px;
    width: 20px;
}
.helpbox-btn {
    float: right;
}