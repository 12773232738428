.button-node-box {
    padding: 8px;
    padding-top: 16px;
    padding-bottom: 4px;
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 4px;
    border: outset lightgrey 1px;
    cursor: move;
    background-color: lightgrey;
    box-shadow: 3px 3px 3px #666;
}

.button-slot {
    background-color: #072239;
    padding-left: 2px;
    padding-right: 2px;
    padding-bottom: 4px;
    border-radius: 6px;
    box-shadow: 0px 1px 0px #fff;
    border: 2px grey ridge;
    position: relative;
}

.canvas .button {
    pointer-events: auto;
    cursor: pointer;
}
.button {
    background-color: #3bb3e0;
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 4px;
    padding-bottom: 4px;
    position: relative;
    top: -4px;
    color: #fff;
    background-image: linear-gradient(to bottom, rgb(44, 160, 202) 0%, rgb(62, 184, 229) 100%);
    box-shadow: inset 0px 1px 0px #7fd2f1, 0px 6px 0px #156785;
    border-radius: 5px;
}

.button-slot.pressed {

}

.pressed .button {
    color: #156785;
    text-shadow: 0px 1px 1px rgba(255,255,255,0.3);
    background: rgb(44,160,202);
    box-shadow: inset 0px 1px 0px #7fd2f1, inset 0px -1px 0px #156785;
    top: 2px;
}


