g.node-drag-mode .connection-line,
g.node-drag-mode .waypoint,
g.node-drag-mode .connection-line,
g.node-drag-mode .waypoint {
    /* when dragging nodes, we want to ignore wires */
    pointer-events: none;
}

.wire-underlay {
    stroke-width: 3;
    stroke: black;
    fill: none;
}

.connection-line.wire {
    stroke-width: 2;
    stroke: black;
    fill: none;
    cursor: move;
    touch-action: none;
    pointer-events: all;
    stroke-linejoin: round;
}

.connection-line.word {
    stroke-width: 4;
}

.connection-line.on {
    stroke: blue;
}

.arrow-hover .connection-line.wire {
    stroke-width: 8;
}

.connection-line.wire.wire-hover {
    stroke-dasharray: 1 0;
    stroke-width: 8;
}
    .connection-line.wire.wire-hover.on {
        stroke-dasharray: 1 0;
    }

.connection-line.wire.oscillating {
    stroke: red;
}

/* waypoints */

.waypoint {
    pointer-events: all;
}

.waypoint:hover {
    stroke: green;
    fill: red;
    stroke-width: 8;
}

/* marching ants animation on wire */
@keyframes marching_ants {
    0% {
        stroke-dashoffset: 0;
    }

    100% {
        stroke-dashoffset: 16;
    }
}
/* only use walking ants for bit-wires. */
.wire.bit.on {
    animation: marching_ants 0.34s steps(12) infinite;
    stroke-dasharray: 4 4;
}



