
.diagram-input-group {
    margin-top: 12px;
    margin-right: 12px;
    border: solid black 4px;
    border-radius: 10px;
    background-color: white;
}
.diagram-output-group {
    margin-right: 12px;
    border: solid black 4px;
    border-radius: 10px;
    background-color: white;
    padding-bottom: 12px;
}

    .diagram-output-group .component-row,
    .diagram-input-group .component-row {
        display: flex;
        flex-direction: row;
    }

.group-label { font-size: small; padding: 2px; }
