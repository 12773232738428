.io-area {
    background-color: lightgrey;
    padding: 8px;
    width: 50px;
}

.blinkenlight {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background: radial-gradient(#030, green);
    border: 4px inset green;
}

.blinkenlight.on {
    border: none;
    box-shadow: 0px 0px 20px 10px lightgreen;
    background: radial-gradient(lightgreen, green);
}
