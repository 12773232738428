table.truth  {
    border-style: hidden;
}

table.truth th,
table.truth td {
        padding: 4px;
        text-align: center;
        border: 1px solid lightblue;
        min-width: 2em;
    }

    table.truth colgroup.input {
        border-right: 3px solid lightblue;
    }

table.truth .error td.flag {
    color: red;
    font-weight: bold;
}
table.truth .ok td.flag {
    font-weight: bold;
}


