.registers {
    display: table;
    margin: 1em;
}
.register {
    display: table-row;
}
.register > span {
    display: table-cell;
    padding-right: 0.5em;
}

h3 {
    font-size: 1rem;
    font-weight: bold;
    margin-top: 1rem;
}

.machine th {
    font-size: small;
    font-style: italic;
    font-weight: normal;
    background-color: transparent;
}
