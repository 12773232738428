

svg { overflow:visible;}

.wire-layer {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
}

.tool {
    height: 100%;
    position: relative;

    display: flex;
    /* resizable */
    min-height: 540px;
    /* room for all connectors in 'instruction decoder' level, which is the widest */
    min-width: 1020px;
    max-width: 2000px;
    /* must be a bit of padding to show the grabber */
    padding-bottom: 6px;
    resize: both;
    overflow: hidden;
    align-items: stretch;
}

/* taller canvas on a larger screen. */
@media (min-height: 700px) {
    .tool {
        min-height: 600px;
    }
}
@media (min-height: 800px) {
    .tool {
        min-height: 700px;
    }
}
@media (min-height: 900px) {
    .tool {
        min-height: 800px;
    }
}
@media (min-height: 1000px) {
    .tool {
        min-height: 900px;
    }
}


.canvas-panel {
    flex-grow: 1;
}

.canvas {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    border: solid 1px rgba(0, 0, 0, .1);
}

.component-canvas {
    background-color: #9999c8;
    border: solid black 4px;
    border-radius: 10px;
    flex-grow: 1;
}

.nodes {
    /* above other canvas elements like input/output nodes and above hint popups */
    z-index: 2;
    position: relative;
}


.diagram-label {
    font-size: 12px;
    font-family: 'Segoe UI', 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
.output-row-label {
    width: 100px;
    padding-left: 20px;
    align-self: center;
}
.input-row-label {
    width: 100px;
    padding-top: 36px;
    padding-left: 20px;
}

.output-node-row {
    min-height: 66px;
    position: relative;
    top: 2px;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    /* so the connectors from output row overlap */
    z-index: 1;
}
.input-node-row {
    min-height: 80px;
    position: relative;
    top: -14px;
    left: 0;
    display: flex;
    flex-direction: row;
}

.dropdown-area {
    align-self: center;
}
