.display-box  {
    background-color: gray;
    padding: 10px;
    border: solid 2px gray;
} 
.screen-outer  { 
    background-color: #002200;
    padding: 20px;
    border-radius: 30px;
    box-shadow: inset 4px 4px 8px black;
    border: inset 4px;
}
.screen { }