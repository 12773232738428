.network-device {
    width: 250px;
    float: right;
    background-color: #666;
    border-radius: 10px;
    margin: 10px;
    padding: 1em;
    border: 1em;
    margin-bottom: 1em;
    border: solid brown 2px;
    color: #ddd;
}

.led-box {
    font-family: Arial, Helvetica, sans-serif;
    font-size: small;
    display: inline-block;
    padding: 4px;
}

.led-on, .led-off {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    margin: 2px;
}

.led-off {
    background: radial-gradient(green, #030);
}

.led-on {
    border: none;
    box-shadow: 0px 0px 20px 5px lightgreen;
    background: radial-gradient(lightgreen, green);
}

.led-on.red {
    border: none;
    box-shadow: 0px 0px 20px 10px lightcoral;
    background: radial-gradient(lightcoral, red);
}

.led-off.red {
    background: radial-gradient(#300, red);
    border: 2px inset red;
}

