
.connection {
    touch-action: none;
}

.event-overlay {
    pointer-events: all;
    stroke: transparent;
    fill: transparent;
}

.triangle {
    fill: white;
    stroke: black;
    stroke-width: 2;
    cursor: default;
    touch-action: none;
}

.triangle-outline {
    fill: transparent;
    stroke: transparent;
    stroke-width: 3;
    cursor: default;
    touch-action: none;
}
    .triangle-outline.hover {
        stroke: green;
    }

.touch-connected-selected .triangle-outline {
    stroke: green;
}



.touch-select-box {
    fill: white;
    stroke: black;
}
.touch-select-text {
    stroke: black;
}

/*

    Manual dragging wire

*/

.connection-line.word {
    stroke-width: 4;
}

    .connection-line.manual-drag {
        stroke-width: 2;
        stroke: black;
        fill: transparent;
        cursor: move;
        touch-action: none;
        stroke-linejoin: round;
        /* 
        Allow dragging the line:
        (if we don't disable pointer-event, the line (when under the mouse) will 
         capture the mouseover events, rather than the underlying drop target)
    */
        pointer-events: none;
    }
