svg {
    overflow: visible;
    /* Make SVG area invisible to pointer events.
        For some reason the svg area captures all pointer events, not just on the painted area
        This prevent dragging/dropping in the area.
        We need to enable pointer-events for children
        
        */
    pointer-events: none;
}

.connector {
    /* In order to position the state display */
    position: relative;
    width: 22px;
    height: 22px;
    flex-shrink: 0;
}

.connector-circle {
    stroke: black;
    stroke-width: 2;
    fill: white;
}

.connector-text {
    stroke: black;
    stroke-width: 0;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 12px;
}

.connector-circle.active-state {
    stroke: blue;
}
.connector.oscillating .connector-circle {
    stroke: red;
}

.connector .width-indicator {
    font-size: 8px;
}

.connector-outline {
    stroke: transparent;
    stroke-width: 2;
    fill: transparent;
}

.source-select-mode .connector-outline {
    /* allow click */
    pointer-events: auto;
    stroke: green;
    stroke-width: 5;
    
}


/* State */

.connector .state {
    font-family: Consolas, Menlo, "Courier New", Courier, monospace;
    font-size: 12px;
    z-index: 1;
    display: block;
    position: absolute;
    bottom: 26px;
    left: 10px;
    background-color: lightblue;
    padding: 1px;
    border: 1px solid darkblue;
    border-radius: 4px;
    line-height: 1em;
}

.connector.oscillating .state {
    background-color: red;
}
