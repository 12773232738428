
.help-expanded {
    color: #fff;
    background-color: rgb(0, 129, 194);
    border-radius: 4px;
    max-width: 60em;
    margin-top: 0.5em;
    margin-bottom: 1em;
}
.help-content {
    padding: 1em;
    user-select: none;
}
.help-content code { color: white; }
.help-expanded .arrow {
    background-color: rgb(0, 129, 194);
    position: relative;
    top: 2px;
    margin-left: 30px;
    transform: translateY(25%) rotate(45deg);
    height: 20px;
    width: 20px;
}
.help-content .btn-close {
    float: right;
    padding-left: 1em;
}

.hide-help {
    float: right;
    margin-left: 1em;
}
.help-collapsed {
    float: right;
}