.stack-ide {
    display: flex;
}
.ide {
    display: flex;
}

.machine-column {
    margin-left: 2em;
    margin-top: 1em;
}

/* machine */
.machine {
    border: solid black 4px;
    border-radius: 8px;
    background-color: white;
    color: black;
}

.machine-header {
    margin: 1em;
    text-align: center;
}

.manual-test-toolbox .input-row {
    display: inline;
}
.manual-test-toolbox input {
    width: 4em;
    text-align: right;
}

.test-operation {
    margin-top: 2px;
    margin-bottom: 2px;
}