.hint.tip {
    position: absolute;
    /* above the canvas but below free nodes */
    z-index: 1;
}

.hint .box-row {
    display: flex;
    flex-direction: row;
}

.hint .left-arrow {
    width: 14px;
}

.hint .right-arrow {
    width: 14px;
}

.hint .top-arrow {
    height: 14px;
}

.hint .tip-svg {
    position: absolute;
    width: 20px;
    height: 20px;
    z-index: 2;
    overflow: visible;
}

.hint .left-arrow, 
.hint .right-arrow {
    position: relative;
}

.hint .right-arrow .tip-svg {
        top: 10px;
        left: -2px;
    }

    .hint .left-arrow .tip-svg {
        top: 10px;
        left: 5px;
    }

    .hint .top-arrow .tip-svg {
    top: 4px;
    left: 20px;
}

.hint .bottom {
    stroke-width: 2px;
    stroke: black;
    fill: #FBFCC5;
}

.hint .top {
    stroke-width: 0;
    stroke: none;
    fill: #FBFCC5;
}

.hint .close { float: right; }

.hint.tip .inner {
    background-color: #FBFCC5;
    padding: 6px;
    font-size: 12px;
    border: solid 2px black;
    border-radius: 5px;
    min-height: 50px;
    box-shadow: 3px 3px 3px #666;
}

.hint.tip .content {
    text-align: left;
    color: black;
    font-size: 12px;
}

.hint.tip table.truth {
    border-style: hidden;
}

.hint.tip table.truth th,
.hint.tip table.truth td {
        padding: 2px 4px;
        text-align: center;
        border: 1px solid #999;
    }
