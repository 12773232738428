

.output-connector-droptarget {
    fill: transparent;
    stroke: transparent;
}

    .active .output-connector-droptarget.drag-dragover {
        stroke: green;
        stroke-width: 4;
    }

.connector-text {
    stroke: black;
    stroke-width: 0;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 12px;
}

.connector-circle.active-state {
    stroke: blue;
}

.svg-connector.oscillating .connector-circle {
    stroke: red;
}

.width-indicator {
    font-size: 8px;
}

.connector-outline {
    stroke: transparent;
    stroke-width: 2;
    fill: transparent;
}

.source-select-mode .connector-outline {
    /* allow click */
    pointer-events: auto;
    stroke: green;
}

.source-select-mode .output-connector-droptarget {
    /* allow click */
    pointer-events: auto;
}

/* marching ants animation on outline */
@keyframes active_connector {
    0% {
        stroke-dashoffset: 0;
    }

    100% {
        stroke-dashoffset: 4; /*  */
    }
}

.source-select-mode .connector-outline {
    animation: active_connector 0.34s steps(4) infinite;
    stroke-dasharray: 4, 1;
}
.source-select-mode .svg-connector:hover .connector-outline {
    stroke: green;
    stroke-width: 8;
}