/* html */
.input-node .node-box {
    width: 38px;
    min-height: 64px;
    border: none; /* border drawn by group */
    padding-top: 10px;
}

.free-node {
    position: absolute;
    width: auto;
    height: auto;
    /* default */
    z-index: 1;
}
/* When shown in the palette, the node should take up space */
.palette-slot .free-node {
    position: relative;
}
    .free-node.drag-dragging {
        /* when dragging, we want the dragged node to be on top of other nodes */
        z-index: 2;
    }
.free-node .node-box {
    position: relative;
    min-width: 80px;
    min-height: 48px;
    padding-bottom: 8px;
    box-shadow: 3px 3px 3px #666;
}


.output-node .node-box,
.input-node .node-box {
    cursor: default;
}
.node-box {
    background-color: white;
    border: solid black 4px;
    border-radius: 10px;
    padding-top: 6px;
    padding-left: 4px;
    padding-right: 4px;
    text-align: center;
    cursor: move;
}
    .node-box .node-label {
        font-size: 14px;
        font-weight: bold;
    }

    label { margin-bottom: 0; }

    .input-node .node-box .state {
        border: solid 1px lightblue;
        font-family: consolas, "Courier New", Courier, monospace;
        padding: 0 2px;
    }


.input-node,
.output-node {
    position: relative;
}

/*
    Nodes in groups - narrower and tighter
*/
.input-group.multi .node-box {
    width: 30px;
}

.diagram-node {
    overflow: visible;
}
/* disable pointer events when dragging, so get the events for the underlying target instead */
.diagram-node.drag-dragging {
  pointer-events: none;
}

.diagram-node.drag-dragging .node-box {
    pointer-events: none;
  }

.node-label {
    font-family: 'Segoe UI', 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-weight: normal;
}

.oscillating .node-box {
    border-color: red;
}

.connector-row {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.output-connector-row {
    top: -12px;
    left: 10px;
    right: 10px;
}

.input-connector-row {
    bottom: -8px;
    left: 10px;
    right: 10px;
}

.node-toolbar {
    position: absolute;
    top: 0;
    right: 4px;
    display: flex;
}
/* The menu-dropdown is abosolutely positioned
    because we dont want positions position to change when it is hidden as on the palette
*/
.node-menu {
    position: absolute;
    right: -24px;
    /* node is not responsive to pointer events, but help icon should be */
    pointer-events: all;
}

.help-icon {

    font-size: 18px;
    color: lightblue;
    cursor: default;
}

.help-icon:hover {
    color: black;
}

.info-icon {
    font-size: 14px;
    font-weight: bold;
    font-style: italic;
    padding-right: 4px;
    font-family: georgia, serif
}


.palette .connector-row {
    /* clip node when inside palette, so "splitter" connectors will not overflow */
    /* top(from top) right(from left edge) bottom(from top edge) left(from left edge) */
    clip: rect(-10px, 100px, 60px, -20px);
}

.diagram-node .error-message {
    position: absolute;
    width: 200px;
    left: 100px;
    z-index: 2;
    box-shadow: 3px 3px 3px #666;
}

/* Special case nodes */

.splitter-base {
    width: 300px;
    height: 26px;
}
.palette .splitter-base {
    width: 80px;
}
.SPLIT16 {
    background-color: lightgray;
    border-radius: 4px;
}
    .SPLIT16 .node-toolbar {
        position: absolute;
        top: 10px;
        color: white;
    }
    .SPLIT16 .help-icon { color: white; }
        .SPLIT16 .help-icon:hover { color: black; }


.BUNDLE16 {
    background-color: lightgray;
    border-radius: 4px;
}
    .BUNDLE16 .node-toolbar {
        position: absolute;
        top: -10px;
    }
    .BUNDLE16 .help-icon {
        color: white;
    }
        .BUNDLE16 .help-icon:hover { color: black; }

/* BLINKENLIGHT */

.LAMP .help-icon {
    color: white;
}


/* A label only shown when the node is the toolbox */
.diagram-node .palette-label {
    font-size: small;
    text-align: center;
    position: absolute;
    left: -18px;
    top: 5px;
    background-color: lightgray;
    padding: 2px;
    z-index: 2;
    box-shadow: 3px 3px 3px #666;
}


