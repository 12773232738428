.rom-view button {
    /* node is not responsive to pointer event by default */
    pointer-events: all;
    cursor: default;
}

.rom-view .editor {
    pointer-events: all;
    cursor: default;
    user-select: auto;
}
