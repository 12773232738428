.node-help {
    display: block;
    width: 200px;
    position: absolute;
    /* should be above nodes */
    z-index: 2;
    pointer-events: auto
}

.node-help .tip {
    position: absolute;
    top: 0px;
    left: 16px;
}

.node-help .tip-svg {
    position: absolute;
    top: 10px;
    left: -10px;
    z-index: 1;
    width: 12px;
    height: 20px;
}

.node-help .poly1 {
    stroke-width: 1px;
    stroke: #F0C36D;
    fill: #FBFCC5;
}

.node-help .poly3 {
    stroke-width: 2px;
    stroke: #FBFCC5;
    fill: #FBFCC5;
}

.node-help .tip .inner {
    background-color: #FBFCC5;
    padding: 6px;
    font-size: 12px;
    border: solid 1px #F0C36D;
    border-radius: 5px;
    width: 200px;
    min-height: 100px;
    box-shadow: 3px 3px 3px #666;
    pointer-events: auto;
}

.node-help .tip .content {
    text-align: left;
    color: black;
    font-size: 12px;
}

.node-help .close {
    float: right;
}

.node-help .tip table.truth {
    border-style: hidden;
}

.node-help .tip table.truth th,
.node-help .tip table.truth td {
        padding: 2px 4px;
        text-align: center;
        border: 1px solid #999;
    }


    .node-help .node-help {
    position: absolute;
    z-index: 100;
}