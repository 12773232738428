.opcodes-content {
    padding-top: 1em;
    padding-left: 4em;
}


.opcodes-content th,
.opcodes-content td {
    text-align: center;
    border: 1px solid lightblue;
    padding: 2px; 
}
.opcodes-content td.marker {
    padding-left: 4px;
}
.opcodes-content .bit {
    display: block;
    width: 24px;
    border: solid lightblue 2px;
    border-radius: 2px;
    text-align: center;
    font-family: Consolas, Courier New, Courier, monospace;
    margin-top: 4px;
    margin-bottom: 4px;
}
.opcodes-content .opcode {
    font-family: Consolas, Courier New, Courier, monospace;
    text-align: center;
    padding-right: 1em;
}
.opcodes-content table  {
    border-style: hidden;
}

.opcodes-content th {
    text-align: center;
}
