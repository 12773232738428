
table.machine-code {
    font-family: Consolas, Courier New, Courier, monospace;
}
.machine-code th {
   text-align: center;
   border-left: 2px solid grey;
   border-right: 2px solid grey;
   border-bottom: 2px solid black;
}
.machine-code td {
   padding-left: 0px;
   padding-right: 0px;
   border-bottom: 2px solid black;
}
.machine-code td.val {
       text-align: right;
       padding-left: 4px;
       padding-right: 4px;
       border-left: 2px solid grey;
       border-right: 2px solid grey;
   }
.machine-code .bit {
    display: block;
    width: 24px;
    border: solid lightblue 2px;
    border-radius: 2px;
    text-align: center;
    margin-top: 4px;
    margin-bottom: 4px;
}

.machine-code td.gutter {
   width: 30px;
   text-align: center;
   border-right: solid 2px gray;
}
th.gutter {
   border-left: none;
}
td.addr {
   text-align: right;
   padding-right: 4px;
   border-right: 2px solid grey;
   width: 2em;
}
td.assembler {
   padding-left: 4px;
   border-right: 2px solid grey;
}
th.addr {
   writing-mode: vertical-rl;
}


