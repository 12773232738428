
.popup-dialog {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 10px;
    border: solid grey 2px;
    background: white;
    min-width: 400px;
    overflow-x: auto;
    min-height: 200px;
    max-height: 90vh;
    box-shadow: 3px 3px 3px #666;
    /* above canvas+nodes but below about box */
    z-index: 3;
}

.popup-message { width: 400px; }

.popup-dialog .content {
    padding-bottom: 1em;
}

.popup-dialog .buttons {
    vertical-align: bottom;
}

.btn-close {
    float: right;
}

