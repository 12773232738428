textarea {
    width: 40em;
    min-height: 10em;
}
.key { display: inline-block; width: 100px; vertical-align: top; padding-left: 4px; font-weight: bold; }

.other { border: solid 2px transparent; display:inline-block; }
.is-dirty { border: solid 2px green; }

.section { border-top: 1px solid grey; }
