
.body {
    display: grid;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    font-size: 16px;
}

.body-content {
    display: flex;
    flex-direction: column;
}
